<template>
  <el-drawer
    v-model="drawerVisible"
    :destroy-on-close="true"
    size="1000px"
    :title="`${drawerProps.title}活动`"
  >
    <el-form
      ref="ruleFormRef"
      label-width="100px"
      label-suffix=" :"
      :rules="rules"
      :disabled="drawerProps.isView"
      :model="drawerProps.row"
      :hide-required-asterisk="drawerProps.isView"
    >
      <el-form-item label="活动标题" prop="name">
        <el-input
          v-model="drawerProps.row.name"
          placeholder="请填写活动标题"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="活动内容" prop="content">
        <div class="editor-container">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editorRef"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 500px; overflow-y: hidden;"
            v-model="drawerProps.row.content"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated"
            @onChange="handleChange"
          />
        </div>
      </el-form-item>
      <el-form-item label="活动时间" prop="activityDate">
        <el-date-picker
          v-model="drawerProps.row.activityDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="drawerVisible = false">取消</el-button>
      <el-button
        v-show="!drawerProps.isView"
        type="primary"
        @click="handleSubmit"
        >确定</el-button
      >
    </template>
  </el-drawer>
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { activityCreate, activityUpdate } from '@/api/activity'
import {
  ref,
  reactive,
  defineExpose,
  defineEmits,
  onBeforeUnmount,
  shallowRef
} from 'vue'
import { ElMessage } from 'element-plus'

const rules = reactive({
  name: [{ required: true, message: '请填写活动标题' }],
  content: [{ required: true, message: '请填写活动内容' }],
  activityDate: [{ required: true, message: '请选择活动时间' }]
})

const emits = defineEmits('updateList')

// 初始化富文本配置
const mode = ref('default') // 或 'simple'
// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

const toolbarConfig = {}
const editorConfig = { placeholder: '请输入内容...' }

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

const handleCreated = editor => {
  editorRef.value = editor // 记录 editor 实例，重要！
}

const drawerVisible = ref(false)
const drawerProps = ref({
  isView: false,
  title: '',
  row: {
    name: '',
    content: '',
    activityDate: []
  }
})

// 接收父组件传过来的参数
const acceptParams = params => {
  console.log('接收参数', params)
  drawerProps.value = params
  drawerVisible.value = true
}

// 提交数据（新增/编辑）
const ruleFormRef = ref()
const handleSubmit = () => {
  console.log('提交数据', editorRef.value.getHtml())
  ruleFormRef.value.validate(async valid => {
    if (!valid) return
    const parmas = {
      name: drawerProps.value.row.name,
      content: editorRef.value.getHtml(),
      startTime: new Date(drawerProps.value.row.activityDate[0]),
      endTime: new Date(drawerProps.value.row.activityDate[1])
    }
    console.log('提交数据', parmas)
    try {
      if (drawerProps.value.row.id) {
        // 编辑
        await activityUpdate({
          activityId: drawerProps.value.row.id,
          ...parmas
        })
      } else {
        // 新增
        await activityCreate(parmas)
      }
      drawerVisible.value = false
      ElMessage.success('操作成功')
      emits('updateList')
    } catch (error) {
      console.log(error)
    }
  })
}

defineExpose({
  acceptParams
})
</script>

<style lang="scss" scoped>
.editor-container {
  width: 100%;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  overflow: hidden;
}
</style>
