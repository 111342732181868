<template>
  <div class="user-manage-container">
    <el-card class="header table-search" v-if="false">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="活动名称">
          <el-input
            v-model="formInline.user"
            placeholder="请输入活动名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            v-model="formInline.date"
            type="date"
            placeholder="请选择更新时间"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="onSubmit"
            >查询</el-button
          >
          <el-button :icon="Delete" @click="reset"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <div class="filter-container">
        <el-button
          type="primary"
          :icon="CirclePlus"
          @click="openDrawer('新增')"
        >
          新增活动
        </el-button>
        <!-- <el-button
          type="danger"
          plain
          :disabled="multipleSelection.length == 0"
          :icon="Delete"
        >
          批量删除
        </el-button> -->
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中..."
        :data="tableData"
        border
        style="width: 100%"
        header-cell-class-name="table-header"
      >
        <el-table-column label="序号" type="index" align="center" width="70" />
        <el-table-column prop="name" label="活动名称" align="center">
        </el-table-column>
        <el-table-column prop="content" label="活动内容" align="center">
          <template #default="{ row }">
            <div v-html="row.content"></div>
          </template>
        </el-table-column>
        <el-table-column label="活动时间" align="center">
          <template #default="{ row }">
            {{ $filters.dateFilter(row.startTime) }} ~
            {{ $filters.dateFilter(row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template #default="{ row }">
            {{ row.createTime ? $filters.dateFilter(row.createTime) : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间" align="center">
          <template #default="{ row }">
            {{ row.updateTime ? $filters.dateFilter(row.updateTime) : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="300" align="center">
          <template #default="{row}">
            <el-button
              type="primary"
              link
              :icon="Edit"
              @click="openDrawer('编辑', row)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              link
              :icon="View"
              @click="openDrawer('查看', row)"
              >查看</el-button
            >
            <el-button
              type="primary"
              link
              :icon="Delete"
              @click="onRemoveClick(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination"
        :background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </el-card>
    <AddActivityDrawer ref="activityDrawer" @updateList="getListData" />
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import { activityList, activityDelete } from '@/api/activity'
import { Delete, Edit, Search, CirclePlus, View } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import AddActivityDrawer from './component/AddActivityDrawer.vue'

const formInline = reactive({
  user: '',
  region: '',
  date: ''
})
// 数据相关
const loading = ref(false)
const tableData = ref([])
const page = reactive({
  pageIndex: 1,
  pageSize: 10,
  total: 0
})

// const multipleSelection = ref([])

// 打开 drawer(新增、查看、编辑)
const activityDrawer = ref(null)
const openDrawer = (title, row) => {
  const data = {
    name: row.name,
    content: row.content,
    activityDate: [row.startTime, row.endTime]
  }
  const params = {
    title,
    isView: title === '查看',
    row: { ...row, ...data },
    getListData: getListData
  }
  activityDrawer.value.acceptParams(params)
}

// const handleSelectionChange = val => {
//   multipleSelection.value = val
// }

const onSubmit = () => {
  console.log('submit!')
}

const reset = () => {
  console.log('reset!')
}

// 获取数据的方法
const getListData = async () => {
  try {
    loading.value = true
    const parmas = {
      pageIndex: page.pageIndex,
      pageSize: page.pageSize
    }
    const res = await activityList(parmas)
    loading.value = false
    tableData.value = res.datas
    page.total = res.records
  } catch (error) {
    loading.value = false
  }
}
getListData()
// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  page.pageSize = currentSize
  getListData()
}
/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.pageIndex = currentPage
  getListData()
}

/**
 * 删除用户
 */
const onRemoveClick = row => {
  ElMessageBox.confirm('确定要删除选中的活动吗？', '提示', {
    type: 'warning'
  }).then(async () => {
    await activityDelete({ activityId: row.id })
    ElMessage.success('删除成功')
    // 重新渲染数据
    getListData()
  })
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
.user-manage-container {
  .header {
    @include searchForm;
  }

  .filter-container {
    .el-button {
      margin-bottom: 15px;
    }
  }

  :deep(.table-header) {
    @include tableThead;
  }
  .pagination {
    @include pagination;
  }
}
</style>
