import request from '@/utils/request'

/**
 * 查询活动列表
 */
export const activityList = (data) => {
  return request({
    url: `/admin/activity/list?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * 创建活动
 */
export const activityCreate = data => {
  return request({
    url: '/admin/activity/create',
    method: 'POST',
    data
  })
}

/**
 * 删除活动
 */
export const activityDelete = data => {
  return request({
    url: '/admin/activity/delete',
    method: 'POST',
    data
  })
}

/**
 * 更新活动
 */
export const activityUpdate = data => {
  return request({
    url: '/admin/activity/update',
    method: 'POST',
    data
  })
}
